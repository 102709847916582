module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":["title","alt"],"withWebp":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://directchatnumber.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Direct Chat Number","short_name":"Direct Chat Number","description":"The application does cool things and makes your life better.","lang":"en","display":"standalone","icon":"src/assets/icons/logo2.png","start_url":"/","background_color":"#ffffff","theme_color":"#006064","localize":[{"start_url":"/de/","lang":"de","name":"Die coole Anwendung","short_name":"Coole Anwendung","description":"Die Anwendung macht coole Dinge und macht Ihr Leben besser."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bba1e63a3c183438cd65a828282a0e3d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","localeJsonNodeName":"locales","languages":["ar","de","en","es","fi","fr","hi","it","ja","ko","ms","nl","pl","pt","ru","sv","th","tr","uk","zh"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://directchatnumber.com","trailingSlash":"always","i18nextOptions":{"debug":false,"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":true},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-58QCMVH6","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"96362155","webvisor":false,"trackHash":true,"afterBody":true,"defer":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
