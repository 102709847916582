exports.components = {
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ar-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.ar.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ar-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-de-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.de.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-de-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-es-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.es.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-es-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-fi-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.fi.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-fi-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-fr-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.fr.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-fr-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-hi-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.hi.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-hi-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-it-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.it.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-it-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ja-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.ja.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ja-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ko-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.ko.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ko-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ms-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.ms.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ms-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-nl-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.nl.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-nl-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-pl-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.pl.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-pl-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-pt-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.pt.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-pt-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ru-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.ru.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-ru-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-sv-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.sv.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-sv-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-th-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.th.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-th-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-tr-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.tr.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-tr-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-uk-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.uk.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-uk-mdx" */),
  "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-zh-mdx": () => import("./../../../src/markdown/templates/blog-template.jsx?__contentFilePath=D:/workspace/development/directchatnumber.com/2024_02_21/src/markdown/pages/blog/how-to-create-whatsapp-link/index.zh.mdx" /* webpackChunkName: "component---src-markdown-templates-blog-template-jsx-content-file-path-src-markdown-pages-blog-how-to-create-whatsapp-link-index-zh-mdx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-app-jsx": () => import("./../../../src/pages/privacy/app.jsx" /* webpackChunkName: "component---src-pages-privacy-app-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-privacy-web-jsx": () => import("./../../../src/pages/privacy/web.jsx" /* webpackChunkName: "component---src-pages-privacy-web-jsx" */),
  "component---src-pages-terms-app-jsx": () => import("./../../../src/pages/terms/app.jsx" /* webpackChunkName: "component---src-pages-terms-app-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */),
  "component---src-pages-terms-web-jsx": () => import("./../../../src/pages/terms/web.jsx" /* webpackChunkName: "component---src-pages-terms-web-jsx" */)
}

